.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Wrapper {
  padding: 3em 2em 6em 2em;
  font-family: 'SuisseIntl';
}

.Wrapper a {
  font-size: 0.8em;
}

.highcharts-root{
  font-family: 'SuisseIntl' !important;
}
.highcharts-figure,
.highcharts-data-table table {
    min-width: 360px;
    max-width: 800px;
    margin: 1em auto;
}
.highcharts-background {
  /* fill: #E0E4E5; */
  fill: #fff;
}
.highcharts-data-table table {
    font-family: 'SuisseIntl' !important;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: red;
}

.highcharts-data-table tr:hover {
    background: red;
}

.highcharts-description {
    margin: 0.3rem 10px;
}
.highcharts-title{
  font-size: 2em !important;
  padding: 4px 0px !important;
  font-family: 'SuisseIntl' !important;
}
.highcharts-label, .highcharts-range-input {
  font-size: 1.2em !important;
  padding-right: 2px !important;
  font-family: 'SuisseIntl' !important;
}
.highcharts-axis, .highcharts-yaxis{
  font-size: 1.5em !important;
  font-family: 'SuisseIntl' !important;
}
.highcharts-grid-line{
  stroke: #ebebeb;
}
.highcharts-button-box, text{
  font-size: 1em !important;
  /* padding-right: 4px !important; */
}