body {
  margin: 0;
  background: #E0E4E5;
  
  @media screen and (min-width:1025px){
    margin-left: 14%;
    margin-right: 14%;
  }
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: 'SuisseIntl';
}

a {
  text-decoration: none;
  color: #000;
}

/* span {
  color: #000 !important;
} */
